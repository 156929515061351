import { Heading, Paragraph } from "../Typography/Typography";
import styled from "styled-components";
import { StyledParagraph } from "../Button/Button.styles";
import Button from "../Button/Button";
import { device } from "../../theme/sizes";
import TrustedBkg from "../../images/trusted-by-our-clients-bkg.png";
import MovedImage from "../../images/moved.png";

export const MVPContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
  flex-direction: column;

  @media ${device.tabletL} {
    padding: 0;
    padding: 60px 0;
  }
`;

export const MVPContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
  flex-direction: row-reverse;

  @media ${device.tabletL} {
    flex-direction: column;
    padding: 0;
  }
`;

export const MobileHeading = styled(Heading)`
  font-size: 32px;
  line-height: 40px;
`;

export const MVPImageContainer = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 572px;
  @media ${device.tabletL} {
    height: auto;
  }
`;

export const PerkContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  margin: 24px 0;
  @media ${device.tabletL} {
    margin: 0;
  }
`;

export const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 18%;
  align-items: left;
  margin: 0 auto;

  @media ${device.tabletL} {
    width: 100%;
  }
`;

export const Icon = styled.img`
  width: 32px;
  margin-right: 12px;

  @media ${device.tabletL} {
    width: 24px;
  }
`;

export const PerkIcon = styled.img`
  width: 40px;
  height: 40px;
  margin: auto 12px auto 0;

  @media ${device.tabletL} {
    width: 32px;
    height: 32px;
  }
`;

export const Subtitle = styled(Paragraph)`
  display: flex;
`;

export const PerkContainerTitle = styled(Heading)`
  margin: 0 auto;
  font-size: 32px;
  color: ${({ theme }) => theme.colors.gray};

  @media ${device.tabletL} {
    margin: 1em 0;
    font-size: 24px;
  }
`;

export const Description = styled(Paragraph)`
  font-size: 16px;
  line-height: 26px;
  text-align: left;
`;

export const StyledAnchor = styled.a`
  margin: 2em auto;
  @media ${device.tabletL} {
    margin-top: 24px;
    display: block;
    width: 100%;
    text-align: center;
  }
`;

export const StyledButton = styled(Button)`
  margin-top: 1em;

  ${StyledParagraph} {
    font-size: 20px;
    line-height: 28px;
  }

  @media ${device.tabletL} {
    width: 155px;
    padding: 10px 0;
    margin-top: 0;

    ${StyledParagraph} {
      font-size: 16px;
      line-height: 20px;
    }
  }
`;

export const TimelineContainer = styled.div`
  position: relative;
  text-align: center;
  padding-top: 80px;

  background: url(${TrustedBkg});
  background-repeat: no-repeat;
  background-size: cover;

  @media ${device.tabletL} {
    flex-direction: column;
    padding: 32px;
    text-align: left;
    margin: 0 -32px;
  }
`;

export const StyledHeading = styled(Heading)`
  text-align: center;
  margin: 0 0 1em 0;

  @media ${device.tabletL} {
    text-align: left;
    font-size: 24px;
  }
`;

export const StyledHeadingLanding = styled(Heading)`
  text-align: left;
  padding: 0.4em 0;

  @media ${device.tabletL} {
    text-align: left;
    font-size: 32px;
    line-height: 40px;
  }
`;

export const CaseStudyImage = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  margin: auto;
  border-radius: 16px;
  @media ${device.tabletL} {
    height: auto;
    width: 100%;
  }
`;
