import * as React from "react";
import Layout from "../../components/Layout/Layout";

import Footer from "../../components/Footer";
import ScrollHandler from "../../components/ScrollHander";
import BuildMVP from "../../components/BuildMVP";

const BuildMVPPage = () => (
  <>
    <ScrollHandler />
    <Layout>
      <BuildMVP />
      <Footer />
    </Layout>
  </>
);

export default BuildMVPPage;
