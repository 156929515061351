import * as React from "react";

import "react-vertical-timeline-component/style.min.css";
import {
  MVPContainer,
  MVPContentContainer,
  MVPImageContainer,
  PerkContainer,
  PerkContainerTitle,
  CardContainer,
  StyledCard,
  Subtitle,
  Description,
  Icon,
  PerkIcon,
  StyledButton,
  MobileHeading,
  StyledAnchor,
  CaseStudyImage,
  StyledHeading,
  StyledHeadingLanding,
} from "./BuildMVP.styles";
import { Heading, Paragraph } from "../Typography/Typography";
import useMediaQuery from "../../hooks/useMediaQuery";
import { size } from "../../theme/sizes";

import MVPImage from "../../images/app-development-rafiki.png";
import BackgroundImage from "../../images/content_icons/background.png";
import OnboardingImage from "../../images/content_icons/customer_onboarding.png";
import CostEfectiveSupportImage from "../../images/content_icons/cost_effective.png";
import PlanningImage from "../../images/content_icons/ui_ux_valuation.png";
import TechExpertiseImage from "../../images/content_icons/product.png";
import IterativeDevImage from "../../images/content_icons/iterative_development.png";
import CostImage from "../../images/content_icons/technology_evaluation.png";
import TestingImage from "../../images/content_icons/technical_infra.png";
import LeaseSuranceImage from "../../images/leasesurance.png";

const BuildMVP = () => {
  const isMobile = useMediaQuery(size.tabletL);

  return (
    <MVPContainer id="#build-mvp">
      <MVPContentContainer>
        {isMobile && (
          <MobileHeading weight="semiBold">
            The Challenge: Bringing Ideas to Life
          </MobileHeading>
        )}
        <MVPImageContainer src={MVPImage} alt="build-an-mvp" />
        <PerkContainer>
          {!isMobile && (
            <StyledHeadingLanding weight="semiBold">
              The Challenge: Bringing Ideas to Life
            </StyledHeadingLanding>
          )}
          <Paragraph>
            Non-technical founders who have great ideas can face delays in materializing
            them due to a lack of technical expertise in initiating the build process.
          </Paragraph>
        </PerkContainer>
      </MVPContentContainer>
      <PerkContainer>
        <PerkContainerTitle weight="semiBold">Our Services</PerkContainerTitle>
        <CardContainer>
          <StyledCard>
            <Subtitle weight="semiBold">
              <PerkIcon src={PlanningImage} alt="icon" />
              Scope
            </Subtitle>
            <Description>
              Take your ideas and scope them into what defines an MVP
            </Description>
          </StyledCard>

          <StyledCard>
            <Subtitle weight="semiBold">
              <PerkIcon src={TechExpertiseImage} alt="icon" />
              Define
            </Subtitle>
            <Description>
              Translate scope of features into product requirements and define
              your technology stack
            </Description>
          </StyledCard>

          <StyledCard>
            <Subtitle weight="semiBold">
              <PerkIcon src={IterativeDevImage} alt="icon" />
              Build
            </Subtitle>
            <Description>
              Build a team matching the tech stack and begin development
            </Description>
          </StyledCard>
          <StyledCard>
            <Subtitle weight="semiBold">
              <PerkIcon src={CostImage} alt="icon" />
              Test
            </Subtitle>
            <Description>
              Iterate development with unit and performance testing
            </Description>
          </StyledCard>
          <StyledCard>
            <Subtitle weight="semiBold">
              <PerkIcon src={TestingImage} alt="icon" />
              Deploy
            </Subtitle>
            <Description>
              Deliver an MVP with the necessary automations, enabling you
              to integrate and deploy continuously.
            </Description>
          </StyledCard>
        </CardContainer>
        <StyledAnchor
          target="_blank"
          href="https://airtable.com/shrX9O91vMPtuEy99"
        >
          <StyledButton
            label="I want to build an MVP"
            variant="primary"
            weight="semiBold"
          />
        </StyledAnchor>
      </PerkContainer>
    </MVPContainer>
  );
};

export default BuildMVP;
